import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import {
  Text,
  cssColorValue,
  ButtonPrimary,
  cssSpacing,
  screenMdMax,
  titleLarge,
} from '@spotify-internal/encore-web';
import { useTracking } from '../features/Tracking';
import { defaultTheme } from '../features/Theme';
import { NotFound, PageHead } from '../features/Layout';
import { useT } from '@spotify-internal/i18n-core';

import { NextPageContext } from 'next';
import { InteractiveElement } from '../utils/InteractiveElement';
import { useCurrentUser } from '../utils/CurrentUserProvider';

const Layout = styled.div`
  display: flex;
  width: 100%;
  background-color: ${cssColorValue('backgroundBase')};
  padding: 120px ${cssSpacing('looser')};
  height: 100%;
`;

const Content = styled.div`
  width: 100%;
  max-width: 800px;
  text-align: center;
  margin: auto;
  p {
    margin-top: ${cssSpacing('looser-3')};
    margin-bottom: ${cssSpacing('tighter')};
  }
`;

const Headline = styled(Text)`
  font-size: 72px;
  padding-bottom: 0.67em;
  display: block;
  @media (max-width: ${screenMdMax}) {
    ${titleLarge}
  }
`;

const ContentfulError = () => {
  const t = useT();
  const { sendEvent } = useTracking();
  const user = useCurrentUser();
  const loggedIn = user?.hasAccess;

  React.useEffect(() => {
    sendEvent({
      eventCategory: `Frodor - Contentful failed to load`,
      eventAction: 'view',
      eventLabel: 'Contentful failure',
    });
    // run only on page load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ThemeProvider theme={defaultTheme} data-testid="contentful-error">
      <PageHead
        title={t('FRODOR_CONTENTFUL_ERROR_HEADER', 'Content not found', '')}
      />

      <Layout className="encore-creator-light-theme encore-announcement-set">
        <Content>
          <Headline variant="headlineMedium">
            {t(
              'FRODOR_CONTENTFUL_ERROR_HEADING',
              'We couldn’t load this page.',
              "Couldn't means could not",
            )}
          </Headline>

          {loggedIn ? (
            <>
              <Text
                variant="bodyMediumBold"
                style={{
                  display: 'block',
                  marginBlockEnd: cssSpacing('looser'),
                }}
              >
                <div>
                  {t(
                    'FRODOR_CONTENTFUL_ERROR_BODY_LOGGED_IN_DESCRIPTION',
                    'We’re working on getting things back online.',
                    'Text describing basic error',
                  )}
                </div>
                <div>
                  {t(
                    'FRODOR_CONTENTFUL_ERROR_BODY_LOGGED_IN_ADVICE',
                    'Meanwhile, you can go to your dashboard or check back later.',
                    'Advice on what to do since page is not working',
                  )}
                </div>
              </Text>
              <InteractiveElement
                customComponent={ButtonPrimary}
                colorSet="invertedDark"
                href="https://artists.spotify.com/c"
                trackingLabel="Go to dashboard"
                trackingCategory="Frodor - Contentful failed to load"
              >
                {t(
                  'FRODOR_CONTENTFUL_ERROR_CTA_LOGGED_IN',
                  'Go to dashboard',
                  '',
                )}
              </InteractiveElement>
            </>
          ) : (
            <>
              <Text
                variant="bodyMediumBold"
                style={{
                  display: 'block',
                  marginBlockEnd: cssSpacing('looser'),
                }}
              >
                {t(
                  'FRODOR_CONTENTFUL_ERROR_BODY_LOGGED_OUT',
                  'We’re working on getting things back online. Meanwhile, you can try again or check back later.',
                  'Link here refers to a web link',
                )}
              </Text>
              <InteractiveElement
                customComponent={ButtonPrimary}
                onClick={() => {
                  window.location.reload();
                }}
                colorSet="invertedDark"
                trackingLabel="Retry"
                trackingCategory="Frodor - Contentful failed to load"
              >
                {t('FRODOR_CONTENTFUL_ERROR_CTA_LOGGED_OUT', 'Retry', '')}
              </InteractiveElement>
            </>
          )}
        </Content>
      </Layout>
    </ThemeProvider>
  );
};

export default function CustomError({ notFound = false }) {
  if (notFound) return <NotFound />;
  return <ContentfulError />;
}

CustomError.getInitialProps = ({ res, err }: NextPageContext) => {
  const notFound = err?.message === 'NOT_FOUND';

  if (notFound && res) {
    res.statusCode = 404;
  }

  return {
    notFound,
  };
};
